import React, { useMemo, useState } from 'react'
import {
  cpfCnpjMask,
  cpfCnpjUnmask,
  cpfValidator,
  cnpjValidator,
} from 'js-essentials-functions'
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  TextField as Input,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useSignIn } from '../../../context/authentication/signin.context'
import { schemaLogin, validateEmail } from '../validations'
import { authPatient, authPost, authBtoB } from '../services'

// Components
import { Button, Loading } from '../../../components'
import { alertDanger, alertSuccess } from '../../../utils/toast'
import Text from '../../../components/Text'

// Images
import bgstarbem from '../../../assets/brand/logo@3x.png'

import * as S from './styles'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [admin, setAdmin] = useState(true)
  const [cpf, setCpf] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { signInSuccessPatient, signInSuccess } = useSignIn()
  const history = useHistory()

  const initialValues = useMemo(() => {
    return {
      login: '',
      password: '',
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: schemaLogin,
    // eslint-disable-next-line consistent-return
    onSubmit: async (values: any) => {
      const datas = { email: values.login, password: values.password }
      if (cnpjValidator(values.login)) {
        setLoading(true)
        const responseBtoB = await authBtoB(datas)

        if (responseBtoB.status) {
          setLoading(false)
          signInSuccess(responseBtoB.token)
          history.push('/dashboard')
          alertSuccess('Bem Vindo!')
        } else {
          setLoading(false)
          alertDanger(
            responseBtoB.message || 'Ocorreu um erro, tente novamente!'
          )
        }
      } else if (validateEmail(values.login)) {
        const emails = [
          'unidadesaomiguel01@gmail.com',
          'priscila.paula@docctormed.com.br',
          'falecom@redemedsul.com.br',
        ]

        if (!emails.includes(values.login)) {
          return alertDanger(
            'Ocorreu um erro, não foi possível realizar essa ação!'
          )
        }

        setLoading(true)
        const response = await authPost(datas)
        console.log('response', response)

        if (response.status) {
          setLoading(false)
          signInSuccess(response.token)
          alertSuccess('Bem Vindo!')
          history.push('/dashboard')
        } else {
          setLoading(false)
          alertDanger(response.message || 'Ocorreu um erro, tente novamente!')
        }
      } else {
        alertDanger('Dados inválidos!')
      }
    },
  })

  const loginPatient = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    const response = await authPatient({ cpf: cpfCnpjUnmask(cpf) })
    console.log(response)

    if (response.status) {
      signInSuccessPatient(response.token)
      setLoading(false)
      alertSuccess(response.message)
      history.push('/atendimentos')
      return null
    }
    setLoading(false)

    alertDanger(
      response.message || 'Ocorreu um erro inesperado, tente novamente!'
    )
    return null
  }

  if (loading)
    return <Loading title="Aguarde, você será redirecionado em breve!" />
  return (
    <S.Container>
      <S.ContainerCard>
        <S.Logo>
          <img src={bgstarbem} alt="Logo da StarBem" />
        </S.Logo>
        {admin ? (
          <S.Card>
            <Text
              style={{ marginBottom: '15px', textAlign: 'center' }}
              weight="regular"
              size={24}
            >
              Acesso a Consulta
            </Text>
            <form onSubmit={formik.handleSubmit}>
              <Input
                label="Email ou CNPJ"
                placeholder="Informe seus dados"
                type="text"
                name="login"
                variant="outlined"
                color="secondary"
                className="input-default"
                onChange={formik.handleChange}
                value={formik.values.login}
                error={!!formik.errors.login}
                helperText={formik.errors.login}
              />
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: 10 }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  color="secondary"
                >
                  Senha
                </InputLabel>
                <OutlinedInput
                  label="Senha"
                  id="outlined-basic"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  name="password"
                  onChange={formik.handleChange}
                  autoComplete="off"
                  color="secondary"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={() => {
                          setShowPassword(!showPassword)
                        }}
                        edge="end"
                        style={{ outline: 'none' }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Button
                styles={{ width: '100%', marginTop: 20 }}
                color="primary"
                type="submit"
                disabled={!formik.isValid}
              >
                Entrar
              </Button>
            </form>
          </S.Card>
        ) : (
          <S.Card>
            <Text
              style={{ marginBottom: '15px', textAlign: 'center' }}
              weight="regular"
              size={24}
            >
              Acesso Paciente
            </Text>
            <form onSubmit={loginPatient}>
              <Input
                id="outlined-basic"
                label="CPF"
                placeholder="Informe seu cpf"
                type="text"
                name="cpf"
                variant="outlined"
                className="input-default"
                color="secondary"
                value={cpfCnpjMask(cpf)}
                inputProps={{ maxLength: 14 }}
                onChange={(e) => setCpf(e.target.value)}
              />
              <Button
                styles={{ width: '100%', marginTop: 30 }}
                color="primary"
                // onClick={loginPatient}
                disabled={!cpfValidator(cpf)}
                type="submit"
              >
                Entrar
              </Button>
            </form>
          </S.Card>
        )}
        <S.AreaButton>
          <Button
            color="primary"
            type="button"
            size="small"
            onClick={() => setAdmin(!admin)}
          >
            {admin
              ? 'Acesse a área do Paciente'
              : 'Acesse a área administrativa'}
          </Button>
        </S.AreaButton>
      </S.ContainerCard>
    </S.Container>
  )
}

export default Login
